import React, { useContext } from 'react';
import { RichText } from 'prismic-reactjs';
import { Link } from 'gatsby';
import { array, object, string } from 'prop-types';

import { dateToString, langPath } from '@helpers';
import LangContext from '@contexts';
import style from './SlideItem.module.scss';

const SlideItem = ({ data, tags, uid: itemPath }) => {
  const { backgroundpreview, title, date, description } = data;
  const currentLang = useContext(LangContext);
  return (
    <div className={style.item}>
      {backgroundpreview.url ? (
        <img
          className={style.preview}
          src={backgroundpreview.url}
          alt={backgroundpreview.alt}
        />
      ) : (
        <div className={style.placeholder}></div>
      )}
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.title}>
            <RichText render={title.raw} />
          </div>
          <div className={style.block}>
            <ul className={style.categoryList}>
              {tags.map((item, index) => {
                return (
                  <li className={style.tag} key={`${item}${index}`}>
                    {item}
                  </li>
                );
              })}
            </ul>
            <p className={style.date}>{dateToString(date)}</p>
          </div>
        </div>
        <div className={style.description}>
          <RichText render={description.raw} />
        </div>
        <Link
          className={style.link}
          to={langPath(currentLang) + '/blog/' + itemPath}
        >
          Learn More
        </Link>
      </div>
    </div>
  );
};

SlideItem.propTypes = {
  data: object,
  tags: array,
  uid: string,
};

export default SlideItem;
